<template>
    <div>
        <CRow>
            <CCol md="12">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol md="12">
                                Tambah Jenis Tanaman
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CForm v-on:submit.prevent="storeProcess" class="fwdwd">
                        <CCardBody>
                            <div class="form-group">
                                <label for=""><b>Kelompok Jenis Tanaman <span class="text-danger">*</span></b></label>
                                <v-select :options="list_tanaman" label="tk_nama" v-model="kelompok_selected"></v-select>
                            </div>
                            <div class="form-group">
                                <label for=""><b>Sub Kelompok Jenis Tanaman <span class="text-danger">*</span></b></label>
                                <v-select :options="kelompok_selected.tanaman_kategori_sub" label="tks_nama" v-model="subkelompok_selected"></v-select>
                            </div>
                            <div class="form-group">
                                <label for=""><b>Nama Umum <span class="text-danger">*</span></b></label>
                                <input type="text" class="form-control" placeholder="Nama Umum" v-model="nama_umum">
                            </div>
                            <div class="form-group">
                                <label for=""><b>Nama Latin <span class="text-danger">*</span></b></label>
                                <input type="text" class="form-control" placeholder="Nama Latin" v-model="nama_latin">
                            </div>
                            <div class="form-group">
                                <label for=""><b>Slug <span class="text-danger">*</span></b></label>
                                <input type="text" class="form-control" placeholder="Slug" v-model="slug">
                            </div>
                        </CCardBody>
                        <CCardFooter>
                            <div class="text-right">
                                <button type="submit" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
                            </div>
                        </CCardFooter>
                    </CForm>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
    const null_array = [];
    export default {
        name: "EditJenisTanaman",
        components: {
        },
        data() {
            return {
                list_tanaman: null_array,
                kelompok_selected: '',
                subkelompok_selected: '',
                nama_umum: '',
                nama_latin: '',
                slug: '',
                idJenisTanaman: this.$route.params.idJenisTanaman,
            }
        },
        methods: {
            storeProcess: function () {
                let self = this;
                Swal.fire({
                    title: '<i class="fa fa-refresh fa-spin"></i>',
                    text: "Loading...",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
                axios.put(
                    this.apiLink + "api/crud/tanaman",
                    {
                        tanaman_kategori_sub_id: this.subkelompok_selected.id,
                        t_nama: this.nama_umum,
                        t_nama_latin: this.nama_latin,
                        t_slug: this.slug,
                        id: this.idJenisTanaman,
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    }
                )
                .then(function (response) {
                    var res_data = response.data;
                    if (res_data.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res_user.data.message
                        });
                    }
                    else{
                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Anda akan diarahkan ke halaman master jenis tanaman segera",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getContent();
                                    if (content) {
                                        const b = content.querySelector("b");
                                        if (b) {
                                            b.textContent = Swal.getTimerLeft();
                                        }
                                    }
                                }, 100);
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                self.$router.push({name: 'AllJenisTanaman'});
                            }
                        }).catch((error) => {
                            var error_res = error.response.data.meta;
                            if (error_res.code != 200) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: error_res.message
                                });
                            }
                        });
                    }
                });
            },
        },
        created() {
            Swal.fire({
                title: '<i class="fa fa-refresh fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });
            // Get Master Negara
            axios.get(this.apiLink + "api/master/tanaman", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_tanaman = response.data;
                var res_tanaman_data = res_tanaman.data;
                if (res_tanaman.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_tanaman.data.message
                    });
                }
                else{
                    this.list_tanaman = res_tanaman_data.master;
                }
            });
            axios.get(this.apiLink + "api/crud/tanaman", {
                params: {
                    id: this.idJenisTanaman,
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_detail_tanaman = response.data;
                var res_detail_tanaman_data = res_detail_tanaman.data[0].data[0];
                if (res_detail_tanaman.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_detail_tanaman.data.message
                    });
                }
                else{
                    Swal.close();
                    this.nama_umum = res_detail_tanaman_data.t_nama;
                    this.nama_latin = res_detail_tanaman_data.t_nama_latin;
                    this.slug = res_detail_tanaman_data.t_slug;
                    this.kelompok_selected = res_detail_tanaman_data.tanaman_kategori;
                    this.subkelompok_selected = res_detail_tanaman_data.tanaman_kategori_sub;
                    
                }
            });
        }
    };
</script>